<template>
  <page-header :title="title" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
            class="export-btn"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </template>

    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      :loading="tableLoading"
      @currentChange="handleCurrentChange"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import { getOilinSum, ExportOilinSum } from '@/api/oilin';

import DefaultTable from '@/components/DefaultTable/new';
import { columns } from './columns/OilinSum';
// import mixins from '@/mixins';
import { getStorage } from '@/utils/storage';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      site_id: getStorage('site_id'),
      tableLoading: true,
      columns,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  // // mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.tableLoading = true;
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        siteId: this.site_id
      };
      getOilinSum(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.pagination.current = current;
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      let params = {
        siteId: this.site_id
      };
      ExportOilinSum(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
