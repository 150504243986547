export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'siteName',
    align: 'left',
    minWidth: 100
  },
  {
    title: '#92',
    key: 'oil92'
  },
  {
    title: '#95',
    key: 'oil95'
  },
  {
    title: '#98',
    key: 'oil98'
  },
  {
    title: '#0',
    key: 'oil0'
  },
  {
    title: '#10',
    key: 'oil10',
    minWidth: 100
  },
  {
    title: '总油量(L)',
    key: 'takingSum'
  },
  {
    title: '创建时间',
    key: 'createTime',
    minWidth: 100
  }
];
